'use client';

import { useContext } from 'react';

import { Slot } from '@radix-ui/react-slot';

import {
  FullBleedCTAScopeContext,
  FullBleedCTATriggerContext,
} from '~/v1/components/fullBleedCta/fullBleedCta.utils';

import styles from './fullBleedCta.module.scss';

export interface FullBleedCTAProps {
  children: React.ReactNode;
}

/**
 * FullBleedCTA, combined with {@link FullBleedCTATrigger}, creates an element
 * that is completely clickable while being semantically correct. e.x. a card
 *
 * @example
 * <FullBleedCTA>
 *   <article aria-label="Defining Public Knowledge with Program Officer Patricia Hswe">
 *     <h2>Defining Public Knowledge with Program Officer Patricia Hswe</h2>
 *     <FullBleedCTATrigger>
 *       <Link href="/article/defining-public-knowledge-with-program-officer-patricia-hswe">
 *         Read the article
 *       </Link>
 *     </FullBleedCTATrigger>
 *   </article>
 * </FullBleedCTA>
 */
export function FullBleedCTAScope({ children }: FullBleedCTAProps) {
  return (
    <FullBleedCTAScopeContext.Provider value={true}>
      <Slot className={styles.root}>{children}</Slot>
    </FullBleedCTAScopeContext.Provider>
  );
}

export interface FullBleedCTATriggerProps {
  children: React.ReactNode;
}

/**
 * FullBleedCTATrigger wraps the clickable element within a FullBleedCTA. The
 * clickable area of this element will cover the entire FullBleedCTA.
 *
 * {@see FullBleedCTA}
 */
export function FullBleedCTATrigger({ children }: FullBleedCTATriggerProps) {
  const isInFullBleedCTA = useContext(FullBleedCTAScopeContext);

  if (!isInFullBleedCTA) {
    return children;
  }

  return (
    <FullBleedCTATriggerContext.Provider value={true}>
      <Slot className={styles.trigger}>{children}</Slot>
    </FullBleedCTATriggerContext.Provider>
  );
}

export function useIsFullBleedCTATrigger() {
  return useContext(FullBleedCTATriggerContext);
}
