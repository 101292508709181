export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_TRANSPARENT = 'secondary-transparent',
}

export enum ButtonSize {
  REGULAR = 'regular',
  TINY = 'tiny',
  ICON = 'icon',
}
